import React, { Component } from 'react';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import $ from 'jquery';
import { Carousel, Col, Row } from 'antd';
import { getCurrentLanguage, numAutoAdd } from '../utils/utils';
import { homeBanners, clientLogos } from '../data/home';

import operation from '../public/images/operation.png';
import ads from '../public/images/ads.png';

class Home extends Component {
  constructor(props) {
    super(props);
    this.img = undefined;
  }

  componentDidMount() {
    let done = false;
    let top = $('.home_about').offset().top;
    $(document).scroll(function () {
      let scrollTop = $(document).scrollTop();
      let windowHeight = $(window).height();
      if (!done && top) {
        if (parseInt(scrollTop) + parseInt(windowHeight) >= parseInt(top)) {
          let lang = getCurrentLanguage();
          if (lang === 'cn') {
            numAutoAdd($('.numAutoAdd:eq(0)'), { num: 30 });
            numAutoAdd($('.numAutoAdd:eq(1)'), { num: 5000 });
            numAutoAdd($('.numAutoAdd:eq(2)'), { num: 15000 });
          }
          if (lang === 'en') {
            numAutoAdd($('.numAutoAdd:eq(0)'), { num: 30, value: '30' });
            numAutoAdd($('.numAutoAdd:eq(1)'), { num: 5000 });
            numAutoAdd($('.numAutoAdd:eq(2)'), { num: 15000 });
          }
          done = true;
        }
      }
    });

    $('.tab-wrapper .tab').hover(function () {
      $('.tab-wrapper .tab').removeClass('active');
      $('.tab-content').removeClass('active');
      $(this).addClass('active');
      let index = $(this).index();
      $('.tab-content').eq(index).addClass('active');
    });
  }

  prev() {
    this.img.prev();
  }

  next() {
    this.img.next();
  }

  render() {
    return (
      <>
        <Head>
          <meta
            name="keywords"
            content="Google广告，Facebook推广，谷歌代理，海外营销推广，广告投放优化"
          />
          <meta
            name="description"
            content="Hidream讯梦国际专注中国企业海外推广的营销全案公司，为出海企业提供广告投放、品牌孵化、社媒运营、独立站建站服务等。Hidream助力中国企业打造海外品牌，圆梦跨境！"
          />
        </Head>

        <main>
          <div id="home" className="home">
            <div className="banner relative m-auto">
              <div
                className="btn absolute left-32 top-1/2 z-10 flex -translate-y-1/2 items-center justify-center rounded-full bg-black bg-opacity-30 xl:cursor-pointer"
                onClick={this.prev.bind(this)}
              >
                <svg className="icon" aria-hidden="true">
                  <use xlinkHref="#icon-hidreamxiangzuo1"></use>
                </svg>
              </div>

              <Carousel
                autoplay
                autoplaySpeed={8000}
                dots={false}
                ref={(dom) => {
                  this.img = dom;
                }}
              >
                {homeBanners.map((banner) => {
                  return (
                    <div key={banner.link}>
                      {banner.link ? (
                        <Link href={banner.link}>
                          <a>
                            <Image
                              src={banner.image}
                              alt="banner"
                              layout="responsive"
                              priority
                            />
                          </a>
                        </Link>
                      ) : (
                        <Image
                          src={banner.image}
                          alt="banner"
                          layout="responsive"
                          priority
                        />
                      )}
                    </div>
                  );
                })}
              </Carousel>

              <div
                className="btn absolute right-32 top-1/2 z-10 flex -translate-y-1/2 items-center justify-center rounded-full bg-black bg-opacity-30 xl:cursor-pointer"
                onClick={this.next.bind(this)}
              >
                <svg className="icon" aria-hidden="true">
                  <use xlinkHref="#icon-hidreamxiangyou1"></use>
                </svg>
              </div>
            </div>

            <div className="home_about bg-white">
              <div className="bb m-auto">
                <ul className="row">
                  <li className="col">
                    <div className="title">
                      <h3>千万美金</h3>
                    </div>
                    <p className="des">广告实操经验团队</p>
                  </li>

                  <li className="col">
                    <div className="title">
                      <h3 className="numAutoAdd">30</h3>
                      <span>个</span>
                    </div>
                    <p className="des">百万级DTC品牌成功孵化</p>
                  </li>

                  <li className="col">
                    <div className="title">
                      <h3 className="numAutoAdd">5000</h3>
                      <span>&#43;</span>
                    </div>
                    <p className="des">广告实操经验团队</p>
                  </li>

                  <li className="col">
                    <div className="title">
                      <h3 className="numAutoAdd">15000</h3>
                      <span>&#43;</span>
                    </div>
                    <p className="des">跨境电商人才培训</p>
                  </li>
                </ul>
              </div>
            </div>

            <div className="home_service bg-white">
              <div className="bb m-auto">
                <div className="section_header text-center">
                  <h3>我们的服务</h3>
                  <p>Our service</p>
                </div>

                <div className="tabs inner-content">
                  <div className="tab-wrapper">
                    <div className="tab active">
                      <svg className="icon" aria-hidden="true">
                        <use xlinkHref="#icon-hidreamguanggaotoufang01"></use>
                      </svg>
                      <span className="title">广告投放</span>
                      <svg className="icon rightarrow" aria-hidden="true">
                        <use xlinkHref="#icon-hidreamxiangyou1"></use>
                      </svg>
                    </div>

                    <div className="tab">
                      <svg className="icon" aria-hidden="true">
                        <use xlinkHref="#icon-hidreamdaiyunyingfuhua01"></use>
                      </svg>
                      <span className="title">品牌孵化</span>
                      <svg className="icon rightarrow" aria-hidden="true">
                        <use xlinkHref="#icon-hidreamxiangyou1"></use>
                      </svg>
                    </div>
                  </div>

                  <div className="tab-content-wrapper">
                    <div className="tab-content active">
                      <div className="left">
                        <p>
                          从素材制作到广告投放一站式解决，帮助广告主精准定位用户，有效提升ROI。
                        </p>
                      </div>

                      <div className="right">
                        <ul className="row">
                          <li className="col">
                            <svg className="icon" aria-hidden="true">
                              <use xlinkHref="#icon-hidreammeijieziyuan"></use>
                            </svg>
                            <span>媒介资源合作</span>
                          </li>
                          <li className="col">
                            <svg className="icon" aria-hidden="true">
                              <use xlinkHref="#icon-hidreamguanggaosucai"></use>
                            </svg>
                            <span>广告素材制作</span>
                          </li>
                          <li className="col">
                            <svg className="icon" aria-hidden="true">
                              <use xlinkHref="#icon-hidreamdingzhivi"></use>
                            </svg>
                            <span>定制VI体系</span>
                          </li>
                          <li className="col">
                            <svg className="icon" aria-hidden="true">
                              <use xlinkHref="#icon-hidreamguanggaoyouhua"></use>
                            </svg>
                            <span>广告优化</span>
                          </li>
                        </ul>
                        <div className="tab-img">
                          <Image src={ads} alt="广告投放" />
                        </div>
                      </div>
                    </div>
                    <div className="tab-content">
                      <div className="left">
                        <p>
                          从品牌定位到海外市场营销一站式解决，助力企业成功出海。
                        </p>
                      </div>

                      <div className="right">
                        <ul className="row">
                          <li className="col">
                            <svg className="icon" aria-hidden="true">
                              <use xlinkHref="#icon-hidreamyingxiaozixun"></use>
                            </svg>
                            <span>营销咨询</span>
                          </li>
                          <li className="col">
                            <svg className="icon" aria-hidden="true">
                              <use xlinkHref="#icon-hidreamsiyuliuliang"></use>
                            </svg>
                            <span>私域流量打造</span>
                          </li>
                          <li className="col">
                            <svg className="icon" aria-hidden="true">
                              <use xlinkHref="#icon-hidreamshemeiyunying"></use>
                            </svg>
                            <span>社媒运营</span>
                          </li>
                          <li className="col">
                            <svg className="icon" aria-hidden="true">
                              <use xlinkHref="#icon-hidreampinpaiyingxiao"></use>
                            </svg>
                            <span>品牌营销</span>
                          </li>
                        </ul>
                        <div className="tab-img">
                          <Image src={operation} alt="品牌孵化" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="home-customers">
              <div className="bb">
                <div className="section_header text-center">
                  <h3>我们的客户</h3>
                  <p>Our Customers</p>
                </div>

                <div className="mt-10 grid grid-flow-row grid-cols-6 gap-4">
                  {clientLogos.map((logo) => (
                    <div key={logo.alt}>
                      <Image src={logo.img} alt={logo.alt} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default Home;
