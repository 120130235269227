import moment from "moment";

//获取localStorage
export function getLocalStorage(name) {
	let value = localStorage.getItem(name);
	if (value !== "undefined") {
		return JSON.parse(value);
	} else {
		return null;
	}
}

//获取当前使用的语言
export function getCurrentLanguage() {
	let name = "tab-lang";
	let language = getLocalStorage(name);
	if (language) {
		return language;
	} else {
		let reg = /^zh-/gi;
		if (navigator.language === "zh" || reg.test(navigator.language)) {
			return "cn";
		} else {
			return "en";
		}
	}
}

// 数字累加
export function numAutoAdd(el, option) {
	let time = 3000;
	// 最终值
	let finalNum = option.num || el.data("value");
	let finalValue = option.value || null;
	//	调速器
	let regulator = option.regulator || 100;
	let step = finalNum / (time / regulator);
	let count = 0;
	let initial = 0;

	let timer = setInterval(function () {
		count = count + step;
		if (count >= finalNum) {
			clearInterval(timer);
			if (finalValue) {
				el.html(finalValue);
			} else {
				el.html(finalNum);
			}
			return;
		}

		//t未发生改变的话就直接返回
		//避免调用text函数，提高DOM性能
		let t = Math.floor(count);
		if (t === initial) {
			return;
		}
		initial = t;
		el.html(initial);
	}, 80);
}

// 获取模糊时间
export function timerFormat2(time) {
	var nowTime = new Date();
	var year = nowTime.getFullYear();
	var day = nowTime.getDate();
	var hours = parseInt(nowTime.getHours());
	var minutes = nowTime.getMinutes();
	var seconds = nowTime.getSeconds();
	// 开始分解付入的时间
	var timeyear = time.substring(0, 4);
	var timeday = time.substring(8, 10);
	var timehours = parseInt(time.substring(11, 13));
	var timeminutes = time.substring(14, 16);
	var timeseconds = time.substring(17, 19);
	var d_year = Math.abs(year - timeyear);
	var d_day = Math.abs(day - timeday);
	var d_hours = hours - timehours;
	var d_minutes = Math.abs(minutes - timeminutes);
	var d_seconds = Math.abs(seconds - timeseconds);

	if (d_year > 0) {
		return time;
	}
	if (d_day <= 1) {
		switch (d_day) {
			case 0:
				if (d_hours === 0 && d_minutes > 0) {
					return d_minutes + "分钟前";
				} else if (d_hours === 0 && d_minutes === 0 && d_seconds > 0) {
					return d_seconds + "秒前";
				} else {
					return d_hours + "小时前";
				}
			case 1:
				if (d_hours < 0) {
					return 24 + d_hours + "小时前";
				} else {
					return d_day + "天前";
				}

			default:
				return time;
		}
	} else if (d_day > 1 && d_day < 7) {
		return d_day + "天前";
	} else {
		return moment(time).format("YYYY.MM.DD");
	}
}

export function timerFormat(time) {
	var timestamp = new Date(time).getTime();
	var mistiming = Math.round((Date.now() - timestamp) / 1000);
	var arrr = ["年", "个月", "周", "天", "小时", "分钟", "秒"];
	var arrn = [31536000, 2592000, 604800, 86400, 3600, 60, 1];
	for (var i = 0; i < arrn.length; i++) {
		var inm = Math.floor(mistiming / arrn[i]);
		if (inm !== 0) {
			return inm + arrr[i] + "前";
		}
	}
}
