/* home banner */
import banner1 from "../public/images/主页banner1-pc.jpg";
import banner2 from "../public/images/主页banner2-pc.jpg";
/* client logo */
import unice from "../public/images/1.png";
import uwwo from "../public/images/2.png";
import aowei from "../public/images/3.png";
import tidebuy from "../public/images/4.png";
import zeelool from "../public/images/5.png";
import isee from "../public/images/6.png";
import orderplus from "../public/images/7.png";
import alibaba from "../public/images/8.png";
import tencent from "../public/images/9.png";
import tomtop from "../public/images/10.png";
import ecotric from "../public/images/11.png";
import alipearl from "../public/images/12.png";

export const homeBanners = [
	{ image: banner1, link: "/contactus", alt: "出海" },
	{ image: banner2, link: "", alt: "谷歌" },
];

export const clientLogos = [
	{
		img: unice,
		alt: "unice",
	},
	{
		img: uwwo,
		alt: "uwwo",
	},
	{
		img: aowei,
		alt: "aowei",
	},
	{
		img: tidebuy,
		alt: "tidebuy",
	},
	{
		img: zeelool,
		alt: "zeelool",
	},
	{
		img: isee,
		alt: "isee",
	},
	{
		img: orderplus,
		alt: "orderplus",
	},
	{
		img: alibaba,
		alt: "alibaba",
	},
	{
		img: tencent,
		alt: "tencent",
	},
	{
		img: tomtop,
		alt: "tomtop",
	},
	{
		img: ecotric,
		alt: "ecotric",
	},
	{
		img: alipearl,
		alt: "alipearl",
	},
];

const homeData = { homeBanners, clientLogos };
export default homeData;
